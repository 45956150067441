t.components.appModalCollection = t.libraries.Component.subclass({
    constructor: function($el, b){
        var self = this;
        
        this.collections = t.collections.collection;

        console.log(this.collections);

        if (this.collections.objects.length == 0) {
            $el.find('.wrapper').append("<span>Sorry there isn't any photos in your collection yet.<br>Click on the plus icon at the top center of your screen to browse our image bank.<br>Collect the images by clicking on them!</span>");
        }
    },
    closeModal: function(){
        
        var appmodal = $(this).parents('.app-modal');
        appmodal.velocity("transition.fadeOut", { complete:function(){ appmodal.remove(); } });

    }
});