t.controllers.browse = t.libraries.Controller.subclass({
    subTitle: "Lifestyle Creator",
    _render: function(self){
        var $view = self.$view;

        self.render("browse", function(){

            var $view = this.$view;
            var cat_name = typeof self.category_name !== "undefined" ? self.category_name : false;
            $view.show();
            
            t.app.emit('background', '/assets/img/bg-browse.jpg');

            t.app.session.collection_selector = typeof t.app.session.collection_selector !== "undefined" ? t.app.session.collection_selector : "browsebig";
            $view.find('.active_menu').removeClass('active_menu');
            $view.find('app-button[action="' + t.app.session.collection_selector + '"]').addClass('active_menu');
            
            if (cat_name) {
                var $menu = $view.find('.paradise-top-menu-bar > .menu-right');
                //$menu.find('> app-button').velocity('transition.slideLeftOut', { duration:500 });
                
                $el = $('<app-button/>').attr({
                    "class" : "naked round_button left back_menu",
                    "rv-on-click" : "navigateTo",
                    "href" : "/browse",
                    "label" : self.category_name,
                    "iconposition" : "left",
                    "iconmaterial" : "arrow_forward"
                });
                rivets.init('app-button', $el, {});
                $menu.prev().find('span.chevron_right').last().velocity("transition.slideLeftOut", { duration:500 });
                $menu.find('app-button, span.chevron_right').velocity("transition.slideLeftOut", { duration:500, complete:function(){
                    $menu.append( $el.velocity("transition.slideLeftIn", { duration:500 }) );
                } });

                t.app.emit('effects');
                $menu.find('> app-button').css({display:"block", opacity:1})
                    
            }else{

                t.app.emit('effects', function(){
                
                    $el = $('<browse-cards/>').attr({
                        "class" : "no_title no_img",
                        "rv-on-click" : "uploadNewPhoto"
                    });
                    rivets.init('browse-cards', $el, {});
                    
                    $button = $('<app-button-photo/>').attr({
                        "class" : "add_a_photo",
                        "label" : "Upload one of your own"
                    });
                    rivets.init('app-button-photo', $button, {});
                    $el.find('.the_card').html($('<a/>', { "class":"expand", "text":"ADD A PHOTO" }));
                    $el.find('.the_card').append($button);
                    
                    if( $view.find('browse-cards.no_title.no_img').length ==0 ){
                        $el.insertAfter( $view.find('browse-cards').last() ).velocity("transition.slideDownIn", { duration:500 });
                    }
                
                });
                
            }
            
            self.loaded();
            
            if (!t.app.session.paradise_name) {
                $modal = $('<app-modal-create/>').attr({ "class":"app-modal" });
                rivets.init('app-modal-create', $modal, {});
                $view.append( $modal.velocity({ opacity:1 }, { duration:200 }) );
            }
            
            if (!t.app.session.template) {
                t.app.session.template = true;
                $modal_ = $('<app-modal-template/>').attr({ "class":"app-modal" });
                rivets.init('app-modal-template', $modal_, {});
                $view.append( $modal_.velocity({ opacity:1 }, { duration:200 }) );
            }

            if ( $(window).width() > 1200 ) {
                    
                $(window).on('resize', function(){
                    var win = $(this);
                    if (win.width() <= 1200) {
                        
                        $(window).off('resize');
                        t.app.navigate('/');
                        
                    }
                });
                
            }else{
                
                $(window).on('resize', function(){
                    var win = $(this);
                    if (win.width() > 1200) {
                        
                        $(window).off('resize');
                        t.app.navigate('/');
                        
                    }
                });
                
            }

        });
 
    },
    index: function(request){
        var self = this;
        var $view = this.$view;
        
        var category = typeof request.params.category !== "undefined" ? request.params.category : false;
        self.category_name = false;

            if (category) {

                self.options = typeof t.collections['browseCards'] !== undefined ? t.collections['browseCards'] : false;
                self.categories = typeof t.collections['categories'] !== undefined ? t.collections['categories'] : false;
    
                if(self.categories){
                    
                    self.categories.clear();
                    self.options.clear();

                    self.categories.getAll(category, function(a){
                        self.category_name = a.cat_title;
                        self._render(self);
                    });
                    
                }
                
            }else{

                self.options = typeof t.collections['browseCards'] !== undefined ? t.collections['browseCards'] : false;
                self.categories = typeof t.collections['categories'] !== undefined ? t.collections['categories'] : false;
                self.categories.clear();
                
                if(self.options){
                    if(self.options.empty()){
                        self.options.getAll(function(){

                                self._render(self);
            
                            });
                    }else{
                        
                        self._render(self);
                        
                    }
                }
            
            }

    },
    addPhoto: function(e, self){

        var collection = null;
        var max = null;

        t.app.session.collection_selector = typeof t.app.session.collection_selector !== "undefined" ? t.app.session.collection_selector : "browsebig";
        
        collection = t.collections.collection;
        var modifier = 0;
        switch (t.app.session.collection_selector) {
            case "browsebig":
                max = "4 big images";
            break;
            case "browsesmall":
                var modifier = 4;
                max = "16 small images";
            break;
        }
        
        var image = collection.pull({ id : parseInt($(this).attr("id")), cat_slug: $(this).attr("slug") });
        
        if(typeof image === "undefined"){
            image = {
                id: parseInt($(this).attr("id")),
                img: $(this).attr("img"),
                cat_slug: $(this).attr("slug")
            }
            collection.create(image);
            $(this).addClass("selected");
        }else{
            collection.remove(image);
            $(this).removeClass("selected");
        }
        
        $.growl({ title: (collection.objects.length - modifier) + " out of " + max + " selected", message: "" });

    },
    goToLifestyle: function(e, self){
        
        var $view = $(this).parents('.view');
        var button = $view.find('app-button[action="browsesmall"]');
        if (button.hasClass('active_menu')) {
            $view.find('app-button[action="selectsmall"]').trigger('click');
        }else{
            t.app.navigate("/your-lifestyle");
        }
        
    }
});