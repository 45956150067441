t.components.appTextarea = t.libraries.Component.subclass({
    constructor: function($el, attr){
        this.label = typeof attr.label !== "undefined" ? attr.label : false;
        this.action = typeof attr.action !== "undefined" ? attr.action : false;

        var $textarea = $el.find("label > textarea");
        var $label = $el.find("label > span");

            $label.on("click", function(){
                $textarea.trigger("focus");
            })
            $textarea.on("keyup paste cut", function(){
                $textarea = $(this);
                setTimeout(function(){
                    var value = $textarea.val();
                    if(value.length > 0){
                        $label.hide();
                    }else{
                        $label.show();
                    }
                }, 0);
            })
    }
});