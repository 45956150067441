t.controllers.lifestyle = t.libraries.Controller.subclass({
    subTitle: "North Shore Lifestyle Living",
    _render: function(self){

        self.loaded();
        
        self.render("lifestyle", function(){
        
        var $view = this.$view;
        
         if (!t.app.session.provide) {
            t.app.session.provide = true;
        }else{
            $view.find('.lifestyle_popup').remove();
        }
        
        if( self.backButton ){
            
            $view.find('#backButton').velocity("transition.fadeIn", { duration:500 });
            
        }else{
            
            $view.find('#backButton').velocity("transition.fadeOut", { duration:500 });
            
        }
        
        $view.show();

        t.app.emit('background', '/assets/img/bg-home.jpg');
        t.app.emit('effects');
        
        });
 
    },
    index: function(request){
        var self = this;

            self.options = typeof t.collections['lifestyleCards'] !== undefined ? t.collections['lifestyleCards'] : false;

                    var categories = ["cafes", "horses", "toys", "water-sports", "schools", "biking"];
                    var results = [];
                    var titles = [];

                    if (request.params.cat_slug) {
                        
                        self.backButton = true;
                        
                        self.options.getCategory(request.params.cat_slug, function(a){
                                a = _.sortBy(a, function(o) { return o.title; });
                                t.collections['lifestyleCards'].objects = a;
                                self._render(self);
                            });
    
                    }else{
                        
                        self.backButton = false;
                        
                        categories = _.sortBy(categories, function(o) { return o; });

                        $.each(categories, function( index, value ) {
                            
                            self.options.getCategory(value, function(a){
                                
                                $.each(a, function(i, v){
                                    
                                    v.category = value;
                                    
                                    });
                                
                                titles = titles.concat({cat_slug:value, id:a[0].id, title:value.replace(/\-/," "), img:a[0].img, content:""});
                                results = results.concat(a);
                                
                                var index = categories.indexOf(value);
                                categories.splice(index, 1);
                                if (categories.length == 0) {
                                    titles = _.sortBy(titles, function(o) { return o.title; });
                                    t.collections['lifestyleCards'].objects = titles;
                                    self._render(self);
                                }
                            });
                            
                        })
                    
                    }

    },
    close: function(event, self){
        
        $(this).parents('div.lifestyle_popup').velocity('transition.slideDownOut', { duration:500 });
        
    }
});