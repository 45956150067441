t.components.appInput = t.libraries.Component.subclass({
    label: "appInput",
    icon: false,
    action: "",
    constructor: function($el, attr){
        
        var self = this;
        self.icon = typeof attr.icon !== "undefined" ? attr.icon : false;
        self.label = typeof attr.label !== "undefined" ? attr.label : false;
        self.labeltop = typeof attr.labeltop !== "undefined" ? attr.labeltop : false;
        self.action = typeof attr.action !== "undefined" ? attr.action : false;
        self.value = typeof attr.value !== "undefined" ? attr.value : "";
        self.disabled = typeof attr.disabled !== "undefined" ? attr.disabled : false;

        var $input = $el.find("label > input");
        var $label = $el.find("label > span");
        
        if(!self.icon){
            $el.find("app-button").remove();
        }else{
            $el.addClass("iconInput");
        }
        
        if (self.value) {
            $input.val(self.value);
        }
        
        if (self.labeltop) {
            $el.addClass("labelTop");
            $el.prepend( $('<span class="labeltop"/>').text(self.labeltop) );
        }
        
        if (self.disabled) {
            $el.addClass("disabled");
            $el.find('input').attr('readonly', '').attr('value', self.label);
        }
        
        if(!self.label){
            $el.addClass("noLabel");
            $label.hide();
        }else{
            
            if (self.label.toLowerCase().indexOf("password") >= 0) {
                $el.find("label > input").attr('type', 'password');
            }
            
            $el.addClass("hasLabel");
            $label.on("click", function(){
                $el.find("label > input").trigger("focus");
            })
            $el.find("label > input").off("keydown keyup paste cut").on("keyup paste cut", function(){
                $input = $(this);
                setTimeout(function(){
                    var value = $input.val();
                    if(value.length > 0){
                        $label.hide();
                    }else{
                        $label.show();
                    }
                }, 0);
            }).trigger('keyup');
        }

    }
});