t.components.lifestyleCards = t.libraries.Component.subclass({
    constructor: function($el, attr){

        var self = this;
        self.img = typeof attr.img !== "undefined" ? attr.img : false;
        self.title = typeof attr.title !== "undefined" ? attr.title : false;
        self.id = typeof attr.cardid !== "undefined" ? attr.cardid : false;
        var card = t.collections['lifestyleCards'].pull({ title : self.title });

        if (card.cat_slug) {
           $el.attr('cat_slug', card.cat_slug); 
        }

        self.text = card.content.replace(/\n/g,"<br>");
        self.text = self.text.replace(/(&#8212;)/g, "---");
        self.text = self.text.replace(/(&#8211;)/g, "--");
        
        
            
        $('<img/>', { 'src' : self.img }).load(function(){
            $el.find(' > .background').css({'background-image':'url("'+self.img+'")'}).velocity("transition.fadeIn", { duration:500 });
        });
        
    },
    blur: function(event, self){
        //$(this).find('span.title').velocity('stop').velocity({ blur:10 }, { duration:500 });
    },
    unblur: function(event, self){
        //$(this).find('span.title').velocity('stop').velocity({ blur:0 }, { duration:500 });
    },
    expandLifestyle: function(event, self){
        
        $card = $(this);
        $wrapper = $(this).parents('.cards_wrapper');
        $buttonright = $wrapper.next('.button_bottom_right');
        $cards = $wrapper.find('lifestyle-cards');
        mobile = $(window).width() < 1200 ? true : false;
        
        if (!$card.attr('cat_slug')) {

            window.open($card.find('span.text').text(), '_blank');
        
        }else{
            
            $cards.velocity('transition.slideLeftOut', {duration:500, stagger:200, complete: function(){                    
                
                    t.app.navigate('/lifestyle/'+$card.attr('cat_slug'));
                
                }});
            
        }        
        
    },
    expandLifestyleBtn: function(event, self){

        $(this).parents('lifestyle-cards').trigger('click');
        
    }
});