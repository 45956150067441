t.controllers.home = t.libraries.Controller.subclass({
    subTitle: "Welcome",
    index: function(){
        
        var self = this;
        var pages = t.collections.pages;
        pages.get(5, function(data){
            self.responseText = data.content;
            self.loaded();
                self.render("home", function(){

                    var $view = this.$view;
                    $view.show();
                    
                    t.app.emit('background', '/assets/img/bg-home.jpg');
                    t.app.emit('effects');
                    
                    });
                
            });
        
    }
});