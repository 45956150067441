t.controllers.contact = t.libraries.Controller.subclass({
    subTitle: "Contact Us",
    index: function(){        
        
        var self = this;
        var pages = t.collections.pages;
        pages.get(27, function(data){
            self.responseText = data.content;
            self.loaded();
                self.render("contact", function(){
            
                    var $view = this.$view;
                    $view.show();
                    
                    t.app.emit('background', '/assets/img/bg-contact.jpg');
                    t.app.emit('effects');
                    
                    });
                
            });
        
    }
});