t.components.appImageUpload = t.libraries.Component.subclass({
    constructor: function($el, attr){

        var self = this;
        self.img = typeof attr.img !== "undefined" ? attr.img : false;
        self.id = typeof attr.id !== "undefined" ? attr.id : false;
        self.aclass = typeof attr.aclass !== "undefined" ? attr.aclass : false;

        var item = t.collections['myParadise'].pull({ img:self.img });
        if (typeof item !== "undefined") {
            $el.addClass('selected');
        }

        if (self.img) {
            $el.find('.the_card').text('');
            $el.addClass('img_displayed');
            $('<img/>', { 'src' : self.img }).load(function(){
                $el.find(' .helper > .background').css({'background-image':'url("'+self.img+'")'}).velocity("transition.fadeIn", { duration:500 });
            });
        }

        $el.attr("img", self.img);
        if (self.aclass && self.aclass!=0) {
            $el.addClass('uploaded').find('.the_card').append('<span class="material green">check_circle</span>');
        }
    
    },
    addTo: function(e, self){
        var $target = $("body").find('.montageTarget');
        if($target.exists){
            var id = parseInt($target.attr("id"));
            var target = t.collections['myParadise'].pull({ id: id });
            target.img = self.img;

            t.app.session.view_selector = typeof t.app.session.view_selector !== "undefined" ? t.app.session.view_selector : "selectbig";

            collection = t.collections.collection;  

            var target_ = collection.pull({ id: self.id });
            if (typeof target_ !== "undefined") {
                target_.selected = "selected";
            }

            t.collections.lifestyle.saveDraftLifestyle();
    
            $("body").find(".closeCollections").trigger("click");
            t.app.controller.index();
        }
    },
    remove: function(e, self){

        e.stopPropagation();
        e.stopImmediatePropagation();

        var collection = t.collections.collection;

        //var parent = $(this).parents("app-image-upload");
        var id = parseInt(self.id);
        collection.remove({ id: id});

    }
});