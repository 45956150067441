t.collections.pages = t.libraries.Collection.subclass({
    constructor: t.libraries.Collection.prototype.constructor,
    adapter: function(){
        return new t.libraries.Adapters.jsonService("pages");
    },
    init: function(){
    },
    get: function(id, callback){
        var self = this;
        var conditions = {};
        this.adapter.query(id, conditions, function(data){
            if(data){
                var page = data.ID ? data : false;
                if(page){
                    self.createObjects([page], true);
                }
                callback(self.objects[0]);
            }else{
                callback(false);
            }
        })
    }
});

t.models.pages = t.libraries.Model.subclass({
    constructor: t.libraries.Model.prototype.constructor
});