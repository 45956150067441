t.components.appModalEdit = t.libraries.Component.subclass({
    constructor: function($el, attr){

            var self = this;
            self.name = ( typeof $.cookie('name') !== "undefined" ? $.cookie('name') : '' );
            self.email = ( typeof $.cookie('email') !== "undefined" ? $.cookie('email') : '' );
            self.kmu = ( typeof $.cookie('kmu') !== "undefined" ? $.cookie('kmu') : 0 );
            
            $el.find('app-input#name').attr('value', self.name);
            $el.find('app-input#email').attr('value', self.email);
            $el.find('app-input#lsname').attr('value', t.app.session.paradise_name);
            
            if (t.app.session.paradise_name_editing) {
                
                $el.find('app-button.editing_button_selector').show(200);
                
            }
        
    },
    closeModal: function(){
        
        var appmodal = $(this).parents('.app-modal');
        appmodal.velocity("transition.fadeOut", { complete:function(){ appmodal.remove(); } });
        
    },
    saveLifestyle: function(e, self){
        
        t.app.showLoading();
        $parent = $(this).parents('.app-modal');
        
        if ($(this).hasClass('editing_button_selector')) {
            $parent.find('textarea').attr('readonly', 'readonly');
        }
        
        $inputs = $parent.find('input[type="text"]').not('[readonly]');
       
        $inputs.each(function( index, el ) {
            
            if ($(this).val().length == '0') {
                
                var name = $(this).parent().find('span').text();
                $.growl({ title: "All fields are required", message: "Please " + name });
                t.app.hideLoading();
                throw new Error("Please fill in " + name);
                return;
            
            }
            
          });

        var email = $parent.find('app-input#email input').val();
        var text = $parent.find('app-textarea textarea').val();
        var name = $parent.find('app-input#name input').val();
        var kmu = ($parent.find('app-checkbox input').prop('checked')?1:0);
        var lsname = $parent.find('app-input#lsname input').val();
        
        $.cookie('email', email);
        $.cookie('name', name);
        $.cookie('kmu', kmu);        

        lifestyle = typeof t.collections['lifestyle'] !== undefined ? t.collections['lifestyle'] : false;
        if ($(this).hasClass('editing_button_selector')) {
            lifestyle.save({ editing : "true", shortcode : t.app.session.paradise_shortcode, text:text, email:email, name:name, kmu:kmu, lsname:lsname, json_text:JSON.stringify(t.collections.myParadise.objects) }, $parent);
        }else{
            lifestyle.save({ text:text, email:email, name:name, kmu:kmu, lsname:lsname, json_text:JSON.stringify(t.collections.myParadise.objects) }, $parent);
        }
        
    }
});