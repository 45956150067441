t.components.appModalCrop = t.libraries.Component.subclass({
    proportions: false,
    constructor: function($el, attr){
        var self =this;
        this.uid = typeof attr.userid !== "undefined" ? attr.userid : "anonymous";

        this.img = typeof attr.img !== "undefined" ? attr.img : false;
    },
    setProportions: function(proportions){
        this.proportions = proportions;
    },
    getProportions: function(){
        return this.proportions;
    },
    cropper: function(e, self){
        var cropper = new Cropper(this, {
        aspectRatio: 16 / 9,
        crop: 
            function(e) {
                self.setProportions(e.detail);
            }
        });
    },
    saveImage: function(e, self){
        var appmodal = $(this).parents('.app-modal');
        var proportions = self.getProportions();
        if(proportions){
            t.app.showLoading();
            t.collections.lifestyle.cropImage(self.img, proportions, self.uid, function(res){
                t.app.hideLoading();
                if(res.result){
                    t.collections.collection.objects.push({ img: res.img });
                    $.growl({ title: "Crop sucessfull", message: "Your cropped image has been added to your collection" });
                    appmodal.velocity("transition.fadeOut", { complete:function(){ appmodal.remove(); } });
                }else{
                    $.growl({ title: "Error", message: "Sorry, there was an error cropping your image" });
                }
            });
        }else{
            $.growl({ title: "Error", message: "Unable to crop this image." });
        }
    },
    closeModal: function(){
        
        var appmodal = $(this).parents('.app-modal');
        appmodal.velocity("transition.fadeOut", { complete:function(){ appmodal.remove(); } });
        
    }
});