t.components.aboutPanel = t.libraries.Component.subclass({
    constructor: function($el, attr){
        
        $el.find(' > .helper').prepend(t.app.controller.responseText);
        $('<span class="about-image" style="opacity:0"></span>').insertAfter($el.find(' > .helper > h1'));
        
        var pages = t.collections.pages;
        pages.get(10, function(data){

            $el.find('span.about-image').html(data.content).velocity("transition.fadeIn", { duration:500 });
            if (data.featured_image) {
                $el.find('span.about-image').prepend('<img src="' + data.featured_image.source + '"/>');
            }

            });
        
    },
    fadeImage: function(e, self){
    var that = $(this);
        $('<img/>', { 'src' : that.attr('src') }).load(function(){
                that.velocity("transition.fadeIn", { duration:500 });
            });
    }
});