t.collections.projectItems = t.libraries.Collection.subclass({
    constructor: t.libraries.Collection.prototype.constructor,
    adapter: function(){
        return new t.libraries.Adapters.jsonService("posts", true);
    },
    init: function(){
    },
    getCategory: function(slug, callback){
        var self = this;
        var conditions = {};
        this.adapter.query('&filter[category_name]=' + slug, conditions, function(data){
            if(data){
                var posts = data.length > 0 ? data : false;
                if(posts){
                    self.createObjects(posts, true);
                }
                $.each(self.objects, function(key, item){
                    
                    var $el = $('<div/>', { html:item.content });
                    var $gallery = $el.find('div.gallery').clone();
                    $el.find('div.gallery').remove();
                    
                    item.gallery = $.map( $gallery.find('img'), function( n ) {
                        return { src: $(n).attr('src').replace('http://www.northernlifestyles.co.nz/', 'http://northernlifestyles.imgix.net/') };
                    });
                    
                    item.id = key + 1;
                    item.label = item.content;
                    item.content = $el.html();
                    item.img = ( item.featured_image ? item.featured_image.source.replace('http://www.northernlifestyles.co.nz/', 'http://northernlifestyles.imgix.net/') : '' );
                    
                    });
                callback(self.objects);
            }else{
                callback(false);
            }
        })
    }
});

t.models.projectItems = t.libraries.Model.subclass({
    constructor: t.libraries.Model.prototype.constructor
});