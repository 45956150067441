t.app = new t.libraries.App({
    title: "Northern Lifestyles",
    subTitle: "Welcome",
    ready:function(self, next){
        t.app.titleBuffer();
        t.app.session = [];
        next();
    },
    routes: {
        "/": "home",
        "/home": "home",
        "/create": "create",
        "/process": "process",
        "/projects": "projects",
        "/lifestyle": "lifestyle",
        "/lifestyle/:cat_slug": "lifestyle",
        "/about": "about",
        "/contact": "contact",
        "/browse": "browse",
        "/browse/:category": "browse",
        "/your-lifestyle": "yourLifestyle",
        "/lifestyle-viewer/:lifestyle_shortcode": "lifestyleViewer"
    },
    service: {
        host: "/wordpress.php?json_route=",
        browse_service: "/services"
    },
    imgix: function(object){
        /*$.each(object, function(i, v){
            if (v.img) {
                v.img = v.img.replace('http://northernlifestyles.co.nz/', 'http://northernlifestyles.imgix.net/');
                v.img = v.img.replace('http://www.northernlifestyles.co.nz/', 'http://northernlifestyles.imgix.net/');
            }
        });*/
    },
    /*service: {
        host: "http://dch.dev/wordpress.php?json_route=",
        browse_service: "http://dch.dev/services"
    },
    imgix: function(object){
        $.each(object, function(i, v){
            if (v.img) {
                v.img = v.img.replace('http://www.northernlifestyles.co.nz/', 'http://northernlifestyles.imgix.net/');
            }
        });
    },*/
    mobileMenu:function(e){

        var that = $(this);
        var left_panel = $('home-left-panel');
        var menu_nav = $('menu-nav');
        var $view = $('t-canvas > .view');
        
        if (!left_panel.hasClass('velocity-animating')) {

            if (!left_panel.hasClass('mobileMenuDisplayed') && $(window).width() < 1200) {

                left_panel.addClass('mobileMenuDisplayed');
                left_panel.velocity("transition.slideLeftIn", { duration:500 });
                
            }else if (left_panel.hasClass('paradiseMenu') && !left_panel.hasClass('paradiseMenuDisplayed')) {
    
                menu_nav.find('span').velocity({'opacity':0}, { duration:200, complete:function(){
                    $(this).text('close').velocity({'opacity':1}, {duration:200});
                    } });

                menu_nav.velocity({'margin-left':'30%'}, { duration:500 });
                $view.velocity({'margin-left':'30%'}, { duration:500 });
                left_panel.addClass('paradiseMenuDisplayed');
                left_panel.velocity({'margin-left':'-30px', 'transform':'translateX(0)'}, { duration:500, complete:function(){
                    left_panel.css({'margin-left':'0'}).removeClass('paradiseMenu');
                    } });

            }else if(left_panel.hasClass('paradiseMenuDisplayed')){
      
                menu_nav.find('span').velocity({'opacity':0}, { duration:200, complete:function(){
                    $(this).text('menu').velocity({'opacity':1}, {duration:200});
                    } });
                
                menu_nav.velocity({'margin-left':'0'}, { duration:500 });
                $view.velocity({'margin-left':'0'}, { duration:500 });
                left_panel.removeClass('paradiseMenuDisplayed');
                left_panel.velocity({'margin-left':'-30%', 'transform':'translateX(30px)', 'left':'30px'}, { duration:500, complete:function(){
                    left_panel.css({'left':'0'}).addClass('paradiseMenu');
                    } });

            }
            
        }
        
    },
    closeSplash:function(e){
        
       $('mobile-splash').velocity('transition.fadeOut', { duration:500 });
        
    }
});
t.app.on({
    background: function(a){
        $('<img/>', { 'src' : a }).load(function(){
            $('t-canvas').find('.view > .background').css({'background-image':'url("'+a+'")'}).velocity("transition.fadeIn", { duration:500 });
            });
    },
    effects: function(callback){
        var _callback = typeof callback !== "undefined" ? callback : function(){};
        var re = /\/(\w*\-*\w*)/i;
        var selector = ( t.app.router.state.route == '/' ? 'home' : re.exec(t.app.router.state.route)[1] );
        var left_panel = $('home-left-panel');
        var menu_nav = $('menu-nav');
        
        if (selector == 'browse' || selector == 'your-lifestyle' || selector == 'create-your-lifestyle' || selector == 'lifestyle-viewer') {
        
            if ($(window).width() > 1200) {
                
                menu_nav.velocity({'margin-left':'0'}, { duration:500 });
                menu_nav.find('span').velocity({'opacity':0}, { duration:200, complete:function(){
                    $(this).text('menu').velocity({'opacity':1}, {duration:200});
                    } });
                
                if (!left_panel.hasClass('displayed')) {

                    menu_nav.velocity("transition.slideLeftIn", { duration:500 });
                    left_panel.removeClass('paradiseMenuDisplayed').addClass('displayed paradiseMenu').velocity("transition.slideLeftIn", { duration:500, stagger:500 });

                }else if(!left_panel.hasClass('paradiseMenu')){

                    menu_nav.velocity("transition.slideLeftIn", { duration:500 });
                    left_panel.removeClass('paradiseMenuDisplayed').velocity({'margin-left':'-30%', 'transform':'translateX(30px)'}, { duration:500, stagger:500 }).addClass('paradiseMenu');

                }else{
               
                    left_panel.removeClass('paradiseMenuDisplayed').velocity({'margin-left':'-30%', 'transform':'translateX(30px)'}, { duration:500, stagger:500 });
                    
                }
            
            }
            
        }else{
            
            left_panel.removeClass('paradiseMenu');
            if ($(window).width() > 1200) {
                menu_nav.velocity("transition.slideLeftOut", { duration:500 });
            }else{
                menu_nav.velocity("transition.slideLeftIn", { duration:500 });
            }
            
        }

        if (!left_panel.hasClass('displayed') || left_panel.hasClass('paradiseMenuDisplayed')) {
           
            if (selector == 'browse' || selector == 'your-lifestyle' || selector == 'lifestyle-viewer') {
                
                left_panel.addClass('displayed paradiseMenu').velocity("transition.slideLeftIn", { duration:500, stagger:500 });
                $('menu-nav').velocity("transition.slideLeftIn", { duration:500, stagger:500 });
                
            }else{

                left_panel.removeClass('paradiseMenuDisplayed').addClass('displayed').velocity("transition.slideLeftIn", { duration:500 });

            }

        }

        $('contact-panel, about-panel, home-right-panel, div.lifestyle_popup, div.paradise-top-menu-bar').velocity("transition.slideLeftIn", { duration:500, stagger:500, "display":"inline-block" });
        $('t-canvas').find('.view > div.cards_wrapper > *').velocity("transition.slideLeftIn", { delay:500, duration:500, stagger:200, "display":"inline-block" });
        
        var a = $('t-canvas').find('.view > div.paradise-wrapper > *').get();
        var b = new Array();
        while(a.length) { b.push(a.splice(0,5)); }

        $(b).velocity("transition.slideUpIn", { delay:500, duration:500, stagger:200, "display":"inline-block", complete: function(){
            $.type(_callback) === "function" ? _callback() : '';
        } });
        
        //$(a).velocity("transition.slideUpIn", { delay:500, duration:500, stagger:200, "display":"inline-block" });
        
        t.app.emit('ready');
    },
    ready: function(){
        var re = /\/(\w*\-*\w*)/i;
        var selector = ( t.app.router.state.route == '/' ? 'home' : re.exec(t.app.router.state.route)[1] );
        var panel = $('home-left-panel');
        var submenu = panel.find(' > div > a.menuDisplayed').parent();
        
        if (panel.hasClass('mobileMenuDisplayed') && $(window).width() < 1200) {

            panel.velocity('transition.slideLeftOut', { duration:500, complete:function(){
                panel.removeClass('mobileMenuDisplayed');
                } });

        }else{
            
            panel.removeClass('mobileMenuDisplayed');
            
        }

        panel.find('> div.active').removeClass('active');
        submenu.find('.sub-menu').slideUp(200).delay(200).css({opacity:0});
        submenu.find(' > a').removeClass('menuDisplayed');
        
        panel.find('> div.' + selector ).addClass('active');
        
        if (selector == 'browse' || selector == 'your-lifestyle' || selector == 'create-your-lifestyle' || selector == 'lifestyle-viewer'){
            t.app.emit('subMenu', 'open');
        }else{
            t.app.emit('subMenu', 'close');
        }
        
        if ($(window).width() > 1200) {
            if (selector == 'projects' || selector == 'lifestyle') {
                panel.css({'background-color':'rgba(0,38,74,1)'});
            }else{
                panel.css({'background-color':'rgba(0,38,74,0.9)'});
            }
        }
       
    },
    subMenu: function(a){
        
        var that = $('.create.your-lifestyle.browse').find('> a');

        if (a=='open') {
            if (!that.hasClass('menuDisplayed')) {
                that.addClass('menuDisplayed');
                that.parent().find('.sub-menu').slideDown().velocity("transition.slideDownIn", { duration:500, stagger:200 });
            }
        }else{
            that.removeClass('menuDisplayed');
            that.parent().find('.sub-menu').slideUp(200).delay(200).css({opacity:0});
        }
        
    }
});