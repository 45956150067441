t.components.appModalUpload = t.libraries.Component.subclass({
    constructor: function($el, attr){
        var self =this;
        this.uid = typeof attr.userid !== "undefined" ? attr.userid : "anonymous";

        this.img = false;
        this.proportions = false;

        $el.find('#fileupload').fileupload({
            dataType: 'json',
            url: "services/uploadPhoto/" + this.uid,
            add: function (e, data) {
                t.app.showLoading();
                var go = data.submit();
            },
            done: function (e, data) {
                t.app.hideLoading();
                if (data.result) {
                    $el.find('#fileupload').hide();
                    self.img = data.result;
                    self.cropper($el);
                }else{
                    $.growl({ title: "Error", message: "Sorry, there was an error while uploading your file" });
                }
            }
        });
        
    },
    cropper: function($el){
        var self = this;
        var cropper = new Cropper($el.find("#cropImage")[0], {
        aspectRatio: 16 / 9,
        crop: function(e) {
            self.proportions = e.detail;
        }
        });
    },
    saveImage: function(e, self){
        var appmodal = $(this).parents('.app-modal');
        if(self.proportions){
            t.app.showLoading();
            t.collections.lifestyle.cropImage(self.img, self.proportions, self.uid, function(res){
                t.app.hideLoading();
                if(res.result){
                    t.collections.collection.objects.push({ img: res.img });
                    $.growl({ title: "Upload sucessfull", message: "Your file has been uploaded to your collection" });
                    appmodal.velocity("transition.fadeOut", { complete:function(){ appmodal.remove(); } });
                }else{
                    $.growl({ title: "Error", message: "Sorry, there was an error saving your image" });
                }
            });
        }else{
            $.growl({ title: "Error", message: "Please select an image to upload" });
        }
    },
    closeModal: function(){
        
        var appmodal = $(this).parents('.app-modal');
        appmodal.velocity("transition.fadeOut", { complete:function(){ appmodal.remove(); } });
        
    }
});