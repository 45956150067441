t.collections.categories = t.libraries.Collection.subclass({
    constructor: t.libraries.Collection.prototype.constructor,
    adapter: function(){
        return new t.libraries.Adapters.jsonService("getCategoryImages", false, "browse_service");
    },
    init: function(){
    },
    getAll: function(slug, callback){
        var self = this;
        var conditions = {};
        this.adapter.query(slug, conditions, function(data){
            if(data && $.isArray(data)){
                t.app.appController.imgix(data);
                self.createObjects(data, true);
                callback(self.objects[0]);
            }else{
                callback(false);
            }
        })
    }
});

t.models.categories = t.libraries.Model.subclass({
    constructor: t.libraries.Model.prototype.constructor
});