t.components.appButton = t.libraries.Component.subclass({
    constructor: function($el, attr){
        this.$el = $el;
        this.href = typeof attr.href !== "undefined" ? attr.href : "#";
        this.iconmaterial = typeof attr.iconmaterial !== "undefined" ? attr.iconmaterial : false;
        this.iconimagematerial = typeof attr.iconimagematerial !== "undefined" ? attr.iconimagematerial : false;
        this.iconposition = typeof attr.iconposition !== "undefined" ? attr.iconposition : 'right';
        this.label = typeof attr.label !== "undefined" ? attr.label : "";
        this.tooltiptext = typeof attr.tooltiptext !== "undefined" ? attr.tooltiptext : false;
        
        if(this.iconmaterial){

            var $icon = $('<span/>', {
                    "class" : 'material'
                }).text(this.iconmaterial);
            
            if (this.iconposition=='right') {
                $el.find('span').append($icon);
            }else{
                $el.find('span').addClass('left').prepend($icon);
            }
            
        }
        if(this.iconimagematerial){
            this.iconimagematerial = '/assets/img/'+this.iconimagematerial+'.png';
            var $icon = $('<img/>', {
                    "class" : 'material'
                }).attr("src", this.iconimagematerial);
            
            if (this.iconposition=='right') {
                $el.find('span').append($icon);
            }else{
                $el.find('span').addClass('left').prepend($icon);
            }
        }
        
        if (this.tooltiptext) {
            
            $el.prepend( $('<span class="tooltip"/>').text(this.tooltiptext) );
            
        }
    },
    onClick: function(e, self){
        if (!self.$el.hasAttr('rv-on-click')) {
            e.stopPropagation();
            var el = this;
            var event = typeof self.attr['t-on-click'] !== "undefined" ? self.attr['t-on-click'] : false;
            
            if(event){
                t.app.controller[event].apply(el, [e, t.app.controller]);
                return false;
            }else if (self.$el.parent().hasAttr('t-on-click')) {
                t.app.controller[self.$el.parent().attr('t-on-click')].apply(el, [e, t.app.controller]);
                return false;
            }
            
        }
    },
    shrink: function(event, self){
        
        var that = $(this).parents('project-cards');
        var wrapper = that.parents('div.cards_wrapper');
        
        if (that.hasClass('expanded')) {
            
            that.find('span.text, app-button, div.gallery-item-wrapper').velocity("transition.slideUpOut", { duration:500, stagger:200, complete:function(){
                
                that.find('a.expand').css({opacity:0}).slideDown(200).velocity("transition.slideDownIn", { duration:500, complete:function(){
                    
                    that.find(' > .helper').velocity({ width:'100%' }, { duration:150 });
                    
                    var _width = "45%";
                    if ($(window).width() <= 1200) {
                        _width = "85%";
                    }
                    
                    that.velocity({ width:_width }, { duration:500, complete:function(){
                    
                        that.removeClass('expanded');
                        wrapper.find('project-cards').not(that).velocity({ width:_width }, { duration:500, stagger:200 });
                        that.trigger("contracted");
                    
                    } });
                
                } });
                
            } }).slideUp(200);
        
        }
        
    },
    paradiseActions: function(){
        
        var action = $(this).attr('action');
        var $view = $(this).parents('.view');
                
        switch (action) {
            case 'selectsmall':
                //$view.find('app-button[action="preview"], app-button[action="edit"]').fadeIn(200);
                //$view.find('app-button#btn_selector').hide();
        
                $view.find('.active_menu').removeClass('active_menu');
                $(this).addClass('active_menu');
                
                t.app.session.view_selector = "selectsmall";
                if ($view.is('your-lifestyle-view')) {
                        $view.addClass('hide_big_label');
                        var select = $view.find('.big');
                        select.css({"cursor":"default", "pointer-events":"none"});
                        
                        var select_s = $view.find('browse-cards').not(".big");
                        if(select_s.css('opacity') == 0){
                            select_s.velocity("transition.fadeIn",{duration:500});
                            select_s.css({"pointer-events":"auto", "cursor":"pointer"});
                        }

                }else{
                    t.app.navigate('/your-lifestyle');
                }
                
            break;
            case 'selectbig':
                
                //$view.find('app-button[action="preview"], app-button[action="edit"]').hide();
                //$view.find('app-button#btn_selector').fadeIn(200);
                
                $view.find('.active_menu').removeClass('active_menu');
                $(this).addClass('active_menu');
                
                t.app.session.view_selector = "selectbig";
                if ($view.is('your-lifestyle-view')) {
                    $view.removeClass('hide_big_label');
                    var select = $view.find('browse-cards').not(".big");
                        if(select.css('opacity') == 1){
                            select.velocity("transition.fadeOut",{duration:500, "display":"inline-block", opacity:0, complete:function(){
                                select.css({"cursor":"default", "pointer-events":"none"});
                                select = $view.find('.big');
                                if(select.css('opacity') == 0){
                                    select.velocity("transition.fadeIn",{duration:500,"pointer-events":"auto", "cursor":"pointer"});
                                }
                            }});
                        }
                }else{
                    t.app.navigate('/your-lifestyle');
                }
                
            break;
            case 'browsesmall':
                //$view.find('app-button[action="preview"], app-button[action="edit"]').hide();
                //$view.find('app-button#btn_selector').fadeIn(200);
                $view.find('.active_menu').removeClass('active_menu');
                $(this).addClass('active_menu');
                
                t.app.session.collection_selector = "browsesmall";
                t.app.navigate('/browse');
            break;
            case 'browsebig':
                //$view.find('app-button[action="preview"], app-button[action="edit"]').hide();
                //$view.find('app-button#btn_selector').fadeIn(200);
                $view.find('.active_menu').removeClass('active_menu');
                $(this).addClass('active_menu');
                
                t.app.session.collection_selector = "browsebig";
                t.app.navigate('/browse');
            break;
            case 'preview':
                
                var cards = $view.find('browse-cards');
                
                if ($view.is('browse-view')) {
                    t.app.session.preview = true;
                    t.app.navigate('/your-lifestyle');
                    break;
                }
                
                if (!$(this).hasClass('active_menu')) {
                    
                    $(this).addClass('active_menu');
                    $view.find('.paradise-top-menu-bar .menu-top app-button, .paradise-top-menu-bar .menu-top span.chevron_right').css({ "visibility":"hidden", "cursor":"default", "pointer-events":"none" });
                    $view.find('.paradise-top-menu-bar .menu-top center h2').text( t.app.session.paradise_name + " created by " +  $.cookie('name'));
                    $view.find('.paradise-top-menu-bar .menu-top center').delay(205).fadeIn(200);

                    cards.addClass('preview_class').css({"cursor":"default", "pointer-events":"none"});
                    $view.find('.paradise-top-menu-bar').on("click", function(){ $.growl({ title: "Preview mode", message: "Click the preview button again to re-enable editing." }); });
                    $.growl({ title: "Preview mode", message: "Click the preview button again to re-enable editing." });
                
                }else{
                   
                    $view.find('.paradise-top-menu-bar .menu-top center').fadeOut(200);
                    $view.find('.paradise-top-menu-bar .menu-top app-button, .paradise-top-menu-bar .menu-top span.chevron_right').delay(205).css({ "visibility":"visible", "cursor":"pointer", "pointer-events":"auto" });
                    $(this).removeClass('active_menu');
                    cards.removeClass('preview_class').css({"cursor":"pointer", "pointer-events":"auto"});
                    $view.find('.paradise-top-menu-bar').off("click");
                    
                }

            break;
            default:
                $el = $('<app-modal-'+action+'/>').attr({ 'class':'app-modal' });
            
                if (action == 'collection') {
                    
                    var collection = t.collections.collection;

                    collection.getAll(function(){
                        rivets.init('app-modal-'+action, $el, {});
                        });
    
                }else{
                    rivets.init('app-modal-'+action, $el, {});
                }
                
                var appmodal = $view.find('.app-modal');
    
                if (appmodal.length>0) {
                    
                    if(!appmodal.is('app-modal-'+action)){
                        appmodal.velocity("transition.fadeOut", { complete:function(){
                            appmodal.remove();
                            $view.append( $el.velocity("transition.fadeIn", { duration:200 }) );
                        } });
                    }
                    
                }else{
                    
                    $view.append( $el.velocity("transition.fadeIn", { duration:200 }) );
                    
                }
                
            break;
        }
        
    },
    copyUrl: function(){
        
        var parent = $(this).parents('.helper').first();
        var input = parent.find('app-input').last().find('input');
        var $temp = $("<input type='hidden'/>");
        parent.append($temp);
        $temp.val(input.attr('value')).select();
        document.execCommand("copy");
        $temp.remove();
        
    },
    closeModal: function(){
        
        var appmodal = $(this).parents('.app-modal');
        appmodal.velocity("transition.fadeOut", { complete:function(){ appmodal.remove(); } });
        
    }
});