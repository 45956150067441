t.controllers.lifestyleViewer = t.libraries.Controller.subclass({
    subTitle: "Lifestyle Viewer",
    selectedImage: false,
    _render: function(self){
        var $view = self.$view;

        self.render("lifestyle-viewer", function(){

            var $view = this.$view;
            $view.show();
            
            self.paradiseauthorname = t.app.session.paradise_author_name_editing;
            self.paradisename = t.app.session.paradise_name_editing;
            
            var pages = t.collections.pages;
            pages.get(214, function(data){
                self.responseText = data.content;
                $(document).find('#fbdescr').attr('content', $("<div>" + self.responseText + "</div>").text());
            });
            
            var arr = $.grep(self.options.objects, function(v) {
                return typeof v.img !== "undefined";
            });

            

            $(document).find('#fbtitle').attr('content', self.paradisename + " created by " + self.paradiseauthorname);
            $(document).find('#fbimage').attr('content', self.screenshot);

            window.prerenderReady = true;

            t.app.emit('background', '/assets/img/bg-browse.jpg');
            
            self.loaded();
            
            $view.find('browse-cards:eq(5), browse-cards:eq(6), browse-cards:eq(7)').wrapAll( "<div class='mp_left_helper'></div>" );
            $view.find('browse-cards:eq(10), browse-cards:eq(11), browse-cards:eq(12)').wrapAll( "<div class='mp_right_helper'></div>" );
            $view.find('browse-cards:eq(15), browse-cards:eq(16), browse-cards:eq(17), browse-cards:eq(18), browse-cards:eq(19)').wrapAll( "<div class='mp_bottom_helper'></div>" );
            
            t.app.emit('effects');
            self.screenshotOGImage($view);
            self.addthis();
        });
 
    },
    index: function(request){
        var self = this;
        var $view = this.$view;
        
        var lifestyle_shortcode = typeof request.params.lifestyle_shortcode !== undefined ? request.params.lifestyle_shortcode : false;
        self.shortcode = lifestyle_shortcode;

        self.options = typeof t.collections['lifestyle'] !== undefined ? t.collections['lifestyle'] : false;

            if(self.options && lifestyle_shortcode){
                if(self.options.empty()){
                    self.options.get(lifestyle_shortcode, function(res){
                        if(res){
                            self.screenshot = res.my_ls_screenshot;
                            self._render(self);
                        }else{
                            t.app.navigate('/');
                        }
                    });
                }else{
                    
                    self._render(self);
                    
                }
            }

    },
    editLifestyle: function(e, s){

        var self = this;
        self.options = typeof t.collections['myParadise'] !== undefined ? t.collections['myParadise'] : false;
        
        pwd = prompt("Please enter the editing code that was sent to you by email:");
        
        t.collections['lifestyle'].auth(t.app.session.paradise_shortcode, pwd, function(data){
            
            if (data.success == true) {
                t.app.session.paradise_name = t.app.session.paradise_name_editing;
                t.app.session.template = true;
                t.collections['myParadise'].objects = t.collections['lifestyle'].objects;
                $.growl({ title: "You can now edit this Lifestyle", message: "Once you are done, you can either send it again or just save your changes." });
                t.app.navigate('/browse');
            }else{
                $.growl({ title: "Sorry", message: "It looks like you've got the wrong password, please try again." });
            }
            
        })
        
    }, 
    printLifestyle(e,s){
        window.print(); 
    },
    screenshotOGImage($parent){
        var self = this;
        if(navigator.userAgent.indexOf("Prerender") < 0){
            setTimeout(function(){
                $parent.addClass("screenshot");

                html2canvas($parent[0], {
                    allowTaint: true,
                    onrendered: function(c){
                        $parent.removeClass("screenshot");
                        var img = c.toDataURL("image/png");
                        t.collections['lifestyle'].saveScreenshot(img, self.shortcode, function(){ });
                        
                    }
                })
            }, 4000)
        }
    },
    addthis: function(){
        var addthisScript = document.createElement('script');
        addthisScript.setAttribute('src', 'http://s7.addthis.com/js/300/addthis_widget.js#domready=1&pubid=ra-59361c57eaceee35')
        document.body.appendChild(addthisScript)
    }
});