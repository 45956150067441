t.controllers.about = t.libraries.Controller.subclass({
    subTitle: "About Grant & James",
    index: function(){
        var self = this;
        var pages = t.collections.pages;
        pages.get(6, function(data){
            self.responseText = data.content;
            self.loaded();
                self.render("about", function(){
            
                    var $view = this.$view;
                    $view.show();
                    
                    t.app.emit('background', '/assets/img/bg-contact.jpg');
                    t.app.emit('effects');
                    
                    });
                
            });
        
    }
});