t.components.appCheckbox = t.libraries.Component.subclass({
    constructor: function($el, attr){
        this.label = typeof attr.label !== "undefined" ? attr.label : false;
        this.smalllabel = typeof attr.smalllabel !== "undefined" ? attr.smalllabel : false;
        this.rvid = typeof attr.rvid !== "undefined" ? attr.rvid : false;
        this.rvclass = typeof attr.rvclass !== "undefined" ? attr.rvclass : false;
        this.initchecked = typeof attr.initchecked !== "undefined" && attr.initchecked == 1 ? 1 : 0;

        if (this.initchecked == 1) {
            $el.find('input').prop('checked', true);
        }
        if (this.rvclass) {
            $el.find('input').addClass(this.rvclass);
        }
    },
    genderChkbx: function($el, attr){
        var chkbx = $(this).find('input');
        var prop = (chkbx.prop('checked')?false:true);
        chkbx.parents('.helper').find('[rvid="maleChkbx"] input, [rvid="femaleChkbx"] input').prop('checked', false);
        chkbx.prop('checked', prop);
    }
});