t.controllers.projects = t.libraries.Controller.subclass({
    subTitle: "Recent Projects",
    _render: function(self){

        self.loaded();
        
        self.render("projects", function(){
                                
        var $view = this.$view;
        $view.show();

        t.app.emit('background', '/assets/img/bg-home.jpg');
        t.app.emit('effects');
        
        /*$view.find('div.cards_wrapper').mousewheel(function(event, delta) {
               this.scrollLeft -= (delta);
               event.preventDefault();
            }); */
        
        });
 
    },
    index: function(){
        var self = this;

            self.options = typeof t.collections['projectItems'] !== undefined ? t.collections['projectItems'] : false;

            if(self.options){
                if(self.options.empty()){
                    self.options.getCategory("recent-projects", function(a){

                            self._render(self);
        
                        });
                }else{
                    
                    self._render(self);
                    
                }
            }

    },
    expand: function(event, self){
        
        var that = $(this);
        var wrapper = that.parents('div.cards_wrapper');
        
        if (!that.hasClass('expanded')) {
            
            wrapper.find('project-cards').not(that).velocity({ width:0 }, { duration:500, stagger:200, complete:function(){
                
                var _width = "40%";
                if ($(window).width() <= 1200) {
                    _width = "100%";
                }
                
                that.find(' > .helper').velocity({ width:_width }, { duration:150 });
                that.addClass('expanded').velocity({ width:'100%' }, { duration:500, complete:function(){
                    
                    that.find('a.expand').velocity("transition.slideUpOut", { duration:500, complete:function(){
                        
                        that.find('span.text, app-button, div.gallery-item-wrapper').css({opacity:0}).slideDown(200).velocity("transition.slideDownIn", { delay:200, duration:500, stagger:200 });
                        
                        } }).slideUp(500);
                    
                    } });
                
                } });
        
        }
        
    },
    nextProject: function(){
        
        var wrapper = $(this).parents('.button_bottom_right').prev('.cards_wrapper');
        wrapper.find('project-cards');
        var cards = wrapper.find('project-cards');
        var currentExpanded = wrapper.find('project-cards.expanded');
        var current = currentExpanded.length ? currentExpanded : _.find(cards, function (x) { return $(x).position().left > 1 });

        var next = $(current).next('project-cards');
        if (next.length == 0) {
            next = wrapper.find('project-cards').first();
        }

        if(currentExpanded.length){
            currentExpanded.find("app-button.returnToProjects").trigger("click");
            currentExpanded.one("contracted", () => {
                next.trigger("click");
            })
        }else{
            $(wrapper).scrollTo(current, 500);
        }
        
        
    },
    prevProject: function(){
        
        var wrapper = $(this).parents('.button_bottom_right').prev('.cards_wrapper');
        wrapper.find('project-cards');
        var cards = wrapper.find('project-cards');
        var currentExpanded = wrapper.find('project-cards.expanded');
        var current = currentExpanded.length ? currentExpanded : _.find(cards, function (x) { return $(x).position().left > -1 });

        var prev = $(current).prev('project-cards');
        if (prev.length == 0) {
            prev = wrapper.find('project-cards').last();
        }

        if(currentExpanded.length){
            currentExpanded.find("app-button.returnToProjects").trigger("click");
            currentExpanded.one("contracted", () => {
                prev.trigger("click");
            })
        }else{
            $(wrapper).scrollTo(prev, 500, { offset:0 });
        }

        
    }
});