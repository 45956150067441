t.components.appModalCreate = t.libraries.Component.subclass({
    returnToLifestyle: false,
    constructor: function($el, attr){

            var self = this;
            self.name = ( typeof $.cookie('name') !== "undefined" ? $.cookie('name') : '' );
            self.email = ( typeof $.cookie('email') !== "undefined" ? $.cookie('email') : '' );
            self.kmu = ( typeof $.cookie('kmu') !== "undefined" ? $.cookie('kmu') : 0 );

            this.returnToLifestyle = ( typeof $.cookie('returnToLifestyle') !== "undefined" ? $.cookie('returnToLifestyle') : false );
            
            $el.find('app-input#name').attr('value', self.name);
            $el.find('app-input#email').attr('value', self.email);
        
    },
    saveUser: function(modal){
        $parent = $(modal).parents('.app-modal');
        $inputs = $parent.find('input[type="text"]');

        if(this.returnToLifestyle){
            $inputs = $inputs.not('app-input#lsname input[type="text"]');
        }
        var success = true;

        $inputs.each(function( index, el ) {
            if ($(this).val().length == '0') {
                
                var name = $(this).parent().find('span').text();
                $.growl({ title: "All field are required", message: "Please " + name });
                t.app.hideLoading();
                success = false;
                throw new Error("Please fill in " + name);
            
            }
            
          });

        var email = $parent.find('app-input#email input').val();
        var name = $parent.find('app-input#name input').val();
        var kmu = ($parent.find('app-checkbox input').prop('checked')?1:0);
        var lsname = $parent.find('app-input#lsname input').val();
        
        $.cookie('email', email);
        $.cookie('name', name);
        $.cookie('kmu', kmu);
        t.app.session.paradise_name = lsname;

        return success;

        
    },
    initParadise: function(e, self){
        
        t.app.showLoading();
        self.saveUser(this);
        
        $.cookie('returnToLifestyle')
        $.cookie('returnToLifestylePwd')

        $parent.velocity("transition.fadeOut", { complete:function(){
            $parent.remove();
            t.app.hideLoading();
            $.growl({ title: "You can now navigate through the categories to select your 4 big images.", message: "" });
        } });
        
    },
    openLifestyle: function(e, self){
        t.app.showLoading();
        self.saveUser(this);

        var returnToLifestylePwd = ( typeof $.cookie('returnToLifestylePwd') !== "undefined" ? $.cookie('returnToLifestylePwd') : false );

        t.collections['lifestyle'].auth(self.returnToLifestyle, returnToLifestylePwd, function(data){
            t.app.hideLoading();
            if (data.success == true) {
                t.collections['lifestyle'].get(self.returnToLifestyle, function(res){
                    if(res){
                        t.app.session.paradise_name = t.app.session.paradise_name_editing;
                        t.app.session.template = true;
                        t.collections['myParadise'].objects = t.collections['lifestyle'].objects;
                        $.growl({ title: "You can now edit this Lifestyle", message: "Once you are done, you can either send it again or just save your changes." });
                        t.app.navigate('/your-lifestyle');
                    }else{
                        $.growl({ title: "Sorry", message: "An unknown error occurred, please clear your cache and try again." });
                    }
                });
            }else{
                $.growl({ title: "Sorry", message: "An unknown error occurred, please clear your cache and try again." });
            }
        })

        
    }
});