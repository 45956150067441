t.components.projectCards = t.libraries.Component.subclass({
    constructor: function ($el, attr) {

        var self = this;
        self.projectid = typeof attr.projectid !== "undefined" ? attr.projectid : false;

        var a = t.collections['projectItems'].pull({id: parseInt(self.projectid)});

        self.gallery = a.gallery;
        self.title = a.title;
        self.text = a.content;

        $('<img/>', {'src': a.img}).load(function () {
            $el.find(' > .background').css({'background-image': 'url("' + a.img + '")'}).velocity("transition.fadeIn", {duration: 500});
        });

    },
    onClick: function (e, self) {
        if (!self.$el.hasAttr('rv-on-click')) {
            e.stopPropagation();
            var el = this;
            var event = typeof self.attr['t-on-click'] !== "undefined" ? self.attr['t-on-click'] : false;

            if (event) {
                t.app.controller[event].apply(el, [e, t.app.controller]);
                return false;
            } else if (self.$el.parent().hasAttr('t-on-click')) {
                t.app.controller[self.$el.parent().attr('t-on-click')].apply(el, [e, t.app.controller]);
                return false;
            }

        }
    },
    loadImage: function (e, self) {

        var $parent = $(this).parent();
        var src = $(this).attr('src');

        $('<img/>', {'src': src}).load(function () {
            $parent.css({'background-image': 'url("' + src + '")'}).velocity("transition.fadeIn", {duration: 500});
        });

    },
    changeBackground: function () {
        $src = $(this).attr('data-src');
        $projectcard = $(this).parents('project-cards').find(' > .background');
        $projectcard.css({'background-image': 'url("' + $src + '")'});
        $projectcard.data('image', $(this).index());
        $(this).parents("projects-view").find(".fullScreenImg").css({'background-image': 'url("' + $src + '")'});
    },
    fullscreenBG: function (e, self) {
        var $img = $(this).clone();
        $img.appendTo($(this).parents("projects-view"));

        $img.html("" +
            "<button onclick='window.galleryImage(-1)' class='left'></button>" +
            "<button onclick='window.galleryImage(1)' class='right'></button>" +
            "<span class='close'>x</span>" +
            "");
        $img.find('button').click(function (event) {
            event.preventDefault();
            event.stopPropagation();
        });

        $img.addClass("fullScreenImg").fadeIn(500);
        $("home-left-panel").fadeOut(500);
        $img.on("click", function () {
            $img.fadeOut(500, function () {
                $(this).remove();
            });
            $("home-left-panel").fadeIn(500);
        });
    }
});