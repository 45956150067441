t.components.processCards = t.libraries.Component.subclass({
    constructor: function($el, attr){

        var self = this;
        self.img = typeof attr.img !== "undefined" ? attr.img : false;
        self.label = typeof attr.label !== "undefined" ? attr.label : false;
        self.id = typeof attr.id !== "undefined" ? attr.id : false;

    },
    onClick: function(e, self){
        if (!self.$el.hasAttr('rv-on-click')) {
            e.stopPropagation();
            var el = this;
            var event = typeof self.attr['t-on-click'] !== "undefined" ? self.attr['t-on-click'] : false;
            
            if(event){
                t.app.controller[event].apply(el, [e, t.app.controller]);
                return false;
            }else if (self.$el.parent().hasAttr('t-on-click')) {
                t.app.controller[self.$el.parent().attr('t-on-click')].apply(el, [e, t.app.controller]);
                return false;
            }
            
        }
    },
    fadeImage: function(e, self){
    var that = $(this);
        $('<img/>', { 'src' : that.attr('src') }).load(function(){
                that.velocity("transition.fadeIn", { duration:500 });
            });
    }
});