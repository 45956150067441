t.collections.posts = t.libraries.Collection.subclass({
    constructor: t.libraries.Collection.prototype.constructor,
    adapter: function(){
        return new t.libraries.Adapters.jsonService("posts", true);
    },
    init: function(){
    },
    get: function(id, callback){
        var self = this;
        var conditions = {};
        this.adapter.query(id, conditions, function(data){
            if(data){
                var page = data.id ? data : false;
                if(page){
                    self.createObjects([page], true);
                }
                callback(self.objects[0]);
            }else{
                callback(false);
            }
        })
    },
    getCategory: function(slug, callback){
        var self = this;
        var conditions = {};
        this.adapter.query('&filter[category_name]=' + slug, conditions, function(data){
            if(data){
                var posts = data.length > 0 ? data : false;
                if(posts){
                    self.createObjects(posts, true);
                }
                $.each(self.objects, function(key, item){
                    item.id = key + 1;
                    item.label = item.content;
                    item.img = ( item.featured_image ? item.featured_image.source : '' );
                    });
                callback(self.objects);
            }else{
                callback(false);
            }
        })
    }
});

t.models.posts = t.libraries.Model.subclass({
    constructor: t.libraries.Model.prototype.constructor
});