$(function () {


    $("body").keydown(function (e) {

        var projectCards = $("project-cards.expanded");
        if (!projectCards.length) {
            return;
        }

        var position = 0;

        if (e.keyCode == 37) { // left
            position = -1;
        }
        else if (e.keyCode == 39) { // right
            position = 1;
        }

        if (!position) {
            return;
        }

        window.galleryImage(position);

    });

    window.galleryImage = function (position) {

        var projectCards = $("project-cards.expanded");
        if (!projectCards.length) {
            return;
        }

        var current = projectCards.find('.background').eq(0).data('image');
        if (!current && current !== 0) {
            current = -1;
        }

        var items = projectCards.find('.gallery-item');

        current += position;

        if (current >= items.length) {
            current = 0;
        }

        if (current < 0) {
            current = items.length - 1;
        }

        items.eq(current).click();
    };


});