t.controllers.yourLifestyle = t.libraries.Controller.subclass({
    subTitle: "Your Lifestyle",
    selectedImage: false,
    _render: function(self){
        var $view = self.$view;

        self.render("your-lifestyle", function(){

            var $view = this.$view;
            $view.show();
            
            t.app.emit('background', '/assets/img/bg-browse.jpg');
            
            self.loaded();

            self.swappable(this.$view);
            
            $view.find('.active_menu').removeClass('active_menu');
            $view.find('app-button[action="' + t.app.session.view_selector + '"]').addClass('active_menu');
            
            $view.find('browse-cards:eq(5), browse-cards:eq(6), browse-cards:eq(7)').wrapAll( "<div class='mp_left_helper'></div>" );
            $view.find('browse-cards:eq(10), browse-cards:eq(11), browse-cards:eq(12)').wrapAll( "<div class='mp_right_helper'></div>" );
            $view.find('browse-cards:eq(15), browse-cards:eq(16), browse-cards:eq(17), browse-cards:eq(18), browse-cards:eq(19)').wrapAll( "<div class='mp_bottom_helper'></div>" );
            
            t.app.emit('effects', function(){
                
                switch (t.app.session.view_selector) {
                    case "selectbig":
                        $view.removeClass('hide_big_label');
                        var select = $view.find('browse-cards').not(".big");
                        if(select.css('opacity') == 1){
                            select.velocity("transition.fadeOut",{duration:500, "display":"inline-block", opacity:0, complete:function(){
                                select.css({"cursor":"default", "pointer-events":"none"});
                                select = $view.find('.big');
                                if(select.css('opacity') == 0){
                                    select.velocity("transition.fadeIn",{duration:500,"pointer-events":"auto", "cursor":"pointer"});
                                }
                            }});
                        }
                    break;
                    case "selectsmall":
                        $view.addClass('hide_big_label');
                        var select = $view.find('.big');
                        select.css({"cursor":"default", "pointer-events":"none"});
                        var select_s = $view.find('browse-cards').not(".big");
                        if(select_s.css('opacity') == 0){
                            select_s.velocity("transition.fadeIn",{duration:500, "pointer-events":"auto", "cursor":"pointer"});
                        }
                    break;
                }
                
                if (!t.app.session.paradise_name) {
                    $el = $('<app-modal-create/>').attr({ "class":"app-modal" });
                    rivets.init('app-modal-create', $el, {});
                    $view.append( $el.velocity({ opacity:1 }, { duration:200 }) );
                }
                
                if (!t.app.session.template) {
                    t.app.session.template = true;
                    $modal_ = $('<app-modal-template/>').attr({ "class":"app-modal" });
                    rivets.init('app-modal-template', $modal_, {});
                    $view.append( $modal_.velocity({ opacity:1 }, { duration:200 }) );
                }

                if (t.app.session.preview) {
                    delete t.app.session.preview;
                    $view.find('app-button[action="preview"]').trigger('click');
                }
            //$view.find('app-button[action="' + t.app.session.view_selector + '"]').add;
                
                if ( $(window).width() > 1200 ) {
                    
                    $(window).on('resize', function(){
                        var win = $(this);
                        if (win.width() <= 1200) {
                            
                            $(window).off('resize');
                            t.app.navigate('/');
                            
                        }
                    });
                
                }else{
                
                    $(window).on('resize', function(){
                        var win = $(this);
                        if (win.width() > 1200) {
                            
                            $(window).off('resize');
                            t.app.navigate('/');
                            
                        }
                    });
                    
                }
                
            });

        });
 
    },
    index: function(){
        var self = this;
        var $view = this.$view;
        
        t.app.session.view_selector = typeof t.app.session.view_selector !== "undefined" ? t.app.session.view_selector : "selectbig";


        self.options = typeof t.collections['myParadise'] !== undefined ? t.collections['myParadise'] : false;

            if(self.options){
                if(self.options.empty()){
                    self.options.getAll(function(){
    
                            self._render(self);
            
                        });
                }else{
                    
                    self._render(self);
                    
                }
            }

    },
    openCollection: function(e, self){
        self.selectedImage = parseInt($(this).attr("id"));
        $(this).addClass("montageTarget");
        $("body").find('#openCollection').trigger("click");
    },
    goToLifestyle: function(e, self){
        
        $(this).parents('.view').find('app-button[action="browsesmall"]').trigger('click');
        
    },
    swappable: function($view){
        var original = false;
        var self = this;
        $view.find("browse-cards").draggable({
            revert: true,
            helper: 'clone',
            appendTo: 'body',
            drag: function(e, ui){
                original = parseInt($(e.target).attr("id"));
            },
            stop: function(e, ui) {
                $( e.originalEvent.target ).one('click', function(e){ e.stopImmediatePropagation(); } );
            }
        }).droppable({
            drop: function(e, ui){
                var target = parseInt($(e.target).attr("id"));
                var objA = _.findWhere(t.collections['myParadise'].objects, { id: original });
                var objB = _.findWhere(t.collections['myParadise'].objects, { id: target });
                if(objA && objB){
                    var objAClone = _.clone(objA);
                    var objBClone = _.clone(objB);
                    objAClone.img = objB.img;
                    objBClone.img = objA.img;
                    objA.img = objAClone.img;
                    objB.img = objBClone.img;

                    t.collections.lifestyle.saveDraftLifestyle();
                    self.index();
                }
            }
        })
    }
});