t.collections.myParadise = t.libraries.Collection.subclass({
    constructor: t.libraries.Collection.prototype.constructor,
    adapter: function(){
        return new t.libraries.Adapters.jsonFile("/app/data/myParadise.json");
    },
    init: function(){
    }
});

t.models.myParadise = t.libraries.Model.subclass({
    constructor: t.libraries.Model.prototype.constructor
});