t.components.appModalBuild = t.libraries.Component.subclass({
    constructor: function($el, attr){


        
    },
    closeModal: function(){
        
        var appmodal = $(this).parents('.app-modal');
        appmodal.velocity("transition.fadeOut", { complete:function(){ appmodal.remove(); } });
        
    }
});