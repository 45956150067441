t.controllers.process = t.libraries.Controller.subclass({
    subTitle: "The Full Process",
    _render: function(self){

        self.loaded();

        self.render("process", function(){
            
            var $view = this.$view;
            $view.show();
            
            t.app.emit('background', '/assets/img/bg-process.jpg');
            t.app.emit('effects');

            /*$view.find('div.cards_wrapper').mousewheel(function(event, delta) {
               this.scrollLeft -= (delta);
               event.preventDefault();
            });*/
            
            });
 
    },
    index: function(){
        var self = this;

            self.options = typeof t.collections['posts'] !== undefined ? t.collections['posts'] : false;

            if(self.options){
                if(self.options.empty()){
                    self.options.getCategory('full-process', function(a){

                            self._render(self);

                        });
                }else{
                    
                    self._render(self);
                    
                }
            }

    },
    nextProcess: function(){
        
        var wrapper = $(this).parents('.button_bottom_right').prev('.cards_wrapper');
        wrapper.find('process-cards');
        var cards = wrapper.find('process-cards');
        var current = _.find(cards, function (x) { return $(x).position().left > 1 });

        var next = $(current).next('process-cards');
        if (next.length == 0) {
            next = wrapper.find('process-cards').last();
        }

        $(wrapper).scrollTo(next, 500, { offset:-30 });
        
    },
    prevProcess: function(){
        
        var wrapper = $(this).parents('.button_bottom_right').prev('.cards_wrapper');
        wrapper.find('process-cards');
        var cards = wrapper.find('process-cards');
        var current = _.find(cards, function (x) { return $(x).position().left > -1 });

        var prev = $(current).prev('process-cards');
        if (prev.length == 0) {
            prev = wrapper.find('process-cards').first();
        }

        $(wrapper).scrollTo(prev, 500, { offset:-30 });
        
    }
});